/**
 * @author Jason Curren
 * @description Builds logic around form to help validate before submission
 * ================================================================================================
 */

export const formCheck = {
    props: {
        storedForm: {
            default: () => ({}),
            type: Object,
        },
    },
    data() {
        return {
            formErrors: [],
            formErrorMessages: [],
            formData: {},
            formMessage: "",
            formIgnore: [],
            formPost: true,
            isFormValid: false,
            formLoading: false,
            formSuccess: false,
        };
    },
    created() {
        // Built from props on imported component
        // ----------------------------------------------------------------------------------------
        this.formData = this.componentFormData;
        this.formIgnore = this.componentFormIgnore;
        this.formIgnoreNoPost = this.componentFormIgnoreNoPost;
    },
    methods: {
        addError(key) {
            if (this.formErrors.indexOf(key) === -1) this.formErrors.push(key);

            this.formPost = false;
        },
        checkForm() {
            this.formMessage = this.$tc('messages.required');
            this.formPost = true;

            Object.entries(this.formData).forEach(([key, value]) => {
                !value && !this.formIgnore.includes(key)
                    ? this.addError(key)
                    : this.removeError(key);
            });

            // postForm() is built manually in imported component so it can be customised
            // ------------------------------------------------------------------------------------
            if (this.formPost) this.postForm();
        },
        // check if all fields are filled and no error is present, same as checkForm but just returns boolean
        checkFormValid() {
            let isFormValid = true;
            if (this.formErrors.length) {
                isFormValid = false;
            } else {
                Object.entries(this.formData).forEach(([key, value]) => {
                    if (!value && !this.formIgnore.includes(key)) {
                        isFormValid = false;
                    }
                });
            }
            this.isFormValid = isFormValid;
        },
        checkFormNoPost() {
            this.formMessage = this.$tc('messages.required');
            this.formPost = true;
            Object.entries(this.formData).forEach(([key, value]) => {
                !value && !this.formIgnoreNoPost.includes(key)
                    ? this.addError(key)
                    : this.removeError(key);
            });
            return this.formPost;
        },
        removeError(key) {
            const position = this.formErrors.indexOf(key);

            if (position > -1) this.formErrors.splice(position, 1);
        },
        updateForm(obj) {
            this.formData[obj.key] = obj.value;
        },
    },
};