export const state = () => ({
    tenant: null,
    lang: null,
    logo: null,
    event: {
        name: null,
        type: null,
        slug: null,
        theme: null,
        products: null,
        currency: "",
        region: "",
        availableProductCategories: null,
        selectedCategoryForFiltering: null,
        searchStringForFiltering: null
    },
    hasJustAddedAProductToCart: false,
    promoProduct: null,
    hasPromoExpired: false
});

export const getters = {
    filteredProducts: (state) => {
        if (!state.event.products) return [];

        return state.event.products.filter((product) => {
            const categoryFilter = state.event.selectedCategoryForFiltering
                ? product.products.selected.family === state.event.selectedCategoryForFiltering
                : true; // If no category is selected, allows all products.

            const searchFilter = state.event.searchStringForFiltering
                ? product.name.toLowerCase().includes(state.event.searchStringForFiltering.toLowerCase())
                : true; // If search query is empty, allows all products.

            return categoryFilter && searchFilter;
        });
    }
};

export const mutations = {
    UPDATE_TENANT(state, incomingValue) {
        state.tenant = incomingValue;
    },
    UPDATE_LOGO(state, incomingValue) {
        state.logo = incomingValue;
    },
    UPDATE_EVENT_NAME(state, incomingValue) {
        state.event.name = incomingValue;
    },
    UPDATE_EVENT_TYPE(state, incomingValue) {
        state.event.type = incomingValue;
    },
    UPDATE_EVENT_SLUG(state, incomingValue) {
        state.event.slug = incomingValue;
    },
    UPDATE_EVENT_THEME(state, incomingValue) {
        state.event.theme = incomingValue;
    },
    UPDATE_EVENT_PRODUCTS(state, incomingValue) {
        state.event.products = incomingValue;
    },
    UPDATE_EVENT_REGION(state, value) {
        state.event.region = value;
    },
    UPDATE_EVENT_CURRENCY(state, value) {
        state.event.currency = value;
    },
    UPDATE_PROMO_PRODUCT(state, incomingValue) {
        state.promoProduct = incomingValue;
    },
    UPDATE_HAS_PROMO_EXPIRED(state, incomingValue) {
        state.hasPromoExpired = incomingValue;
    },
    UPDATE_HAS_JUST_ADDED_A_PRODUCT_TO_CART(state, incomingValue) {
        state.hasJustAddedAProductToCart = incomingValue;
    },
    UPDATE_AVAILABLE_PRODUCT_CATEGORIES(state, incomingValue) {
        state.event.availableProductCategories = incomingValue;
    },
    UPDATE_SELECTED_CATEGORY_FOR_FILTERING(state, incomingValue) {
        state.event.selectedCategoryForFiltering = incomingValue;
    },
    UPDATE_SEARCH_STRING_FOR_FILTERING(state, incomingValue) {
        state.event.searchStringForFiltering = incomingValue;
    },
}

export const actions = {
    async assignDataFromAPIToStoreFront({ commit }, { newStoreFrontData, slug }) {
        const {
            name, region, vat_rate, not_vat_registered, countries, states, 
            event_products, event_type, expired, files 
        } = newStoreFrontData;

        commit("UPDATE_LOGO", event_type.page.header.logo);
        commit("UPDATE_EVENT_NAME", name);
        commit("UPDATE_EVENT_TYPE", event_type?.type);
        commit("UPDATE_EVENT_THEME", event_type?.theme);
        commit("UPDATE_EVENT_PRODUCTS", event_products);
        commit("UPDATE_HAS_PROMO_EXPIRED", expired || false);

        if (name.replace(/\s+/g, "-") === slug) { // Store slug should match store name.
            commit("UPDATE_EVENT_SLUG", slug);
        }
    },
    changeSelectedCategory({ commit }, category) {
        commit("UPDATE_SELECTED_CATEGORY_FOR_FILTERING", category || null);
    },
    changeSearchString({ commit }, searchString) {
        commit("UPDATE_SEARCH_STRING_FOR_FILTERING", searchString || null);
    },
    getAvailableProductCategories({ commit, state }) {
        let setOfCategories = new Set();

        if (!state.event.products) {
            return;
        }

        for (const product of state.event.products) {
            const category = product.products.selected.family;
            setOfCategories.add(category);
        }
        commit("UPDATE_AVAILABLE_PRODUCT_CATEGORIES", setOfCategories);
    }
}