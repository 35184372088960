
import ProductImageConsumerStoreAndCart from "@/components/display/ProductImageConsumerStoreAndCart";
import {orderCheckout} from "@/mixins/order/orderCheckout";
import moment from "moment/moment";

export default {
        name: "ShoppingCheckout",
        components: {
            ProductImageConsumerStoreAndCart
        },
        mixins:[orderCheckout],
        data() {
            return {
                loading: false,
                publishableKey: this.getStripePublishableKeyByRegion(),
                form: {},
                formErrors: [],
                order: null,
                customerId: null,
                addressStep: true,
                createIntentStep: false,
                showPaymentStep: false,
                showConfirmStep: false,
                basketTaxBreakdown: null,
                shippingPrices: {},
                shippingMethod: null,
                selectedShippingPrice: null,
                elementsOptions: {
                    appearance: {},
                },
                stripeConnectAccountId: undefined,
                confirmParams: {
                    // I have now set redirect if required
                    // cards will not require this return_url, neither will 3d secure
                    return_url: `${window.location.href}/payment-success`,
                },
                paymentEnabled: false,
                prefilledAddress: {
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    postal_code: '',
                    country: '',
                },
                enteredVoucherCode: '',
                hasVoucherOpportunity: false,
                vouchers: {},
                errorMessages: [],
                formMessage: null,
            }
        },
        computed: {
            discountedPrice() {
                if (!this.hasVoucherOpportunity) {
                    return null;
                }
                const voucher = this.vouchers.find((voucher) => voucher.code === this.enteredVoucherCode);

                if (voucher) {
                    return voucher.discount_amount;
                }
                return null;
            },
            totalPriceWithShipping() {
                let output = 0;

                this.$store.state.basket.products.forEach(data => {
                    const price = this.productPrice(data.price,data.store_default_vat);
                    output += parseInt(data.quantity) * parseFloat(price);
                });
                if (this.selectedShippingPrice) {
                    output += parseFloat(this.combinedShippingCost(this.selectedShippingPrice.value,this.totalAdditionalItemCost));
                }

                if (this.discountedPrice) { // Ensure discountedPrice is defined and valid
                    output -= parseFloat(this.discountedPrice);
                }

                return Math.max(output, 0).toFixed(2);
            },
            totalPriceWithShippingNoVat() {
                let output = 0;

                this.$store.state.basket.products.forEach(data => {
                    const price = data.price;
                    output += parseInt(data.quantity) * parseFloat(price);
                });
                if (this.selectedShippingPrice) {
                    output += parseFloat(this.combinedShippingCostNoVat(this.selectedShippingPrice.value,this.totalAdditionalItemCost));
                }

                return output.toFixed(2);
            },
            termsLink() {
                if (this.$store.state.event.region === 'US') {
                    return 'https://udesignme.com/terms-and-conditions-buyer-us';
                }
                else {
                    return 'https://app.merch-platform.com/terms-and-conditions';
                }
            },
            totalPriceWithoutShipping() {
                let output = 0;
                this.$store.state.basket.products.forEach(data => {
                    const price = this.productPrice(data.price,data.store_default_vat);
                    output += parseInt(data.quantity) * price;
                });

                return output.toFixed(2);
            },
            totalShippingPrice() {
                let output = 0;

                if (this.selectedShippingPrice) {
                    output += this.selectedShippingPrice.value;
                    output = parseFloat(output);
                    output += this.totalAdditionalItemCost;
                    const vat = (output * this.$store.state.event.vatRate / 100);
                    return parseFloat(output + vat);
                }

                return output.toFixed(2);
            },
            shippingPriceOptions() {
                if (this.shippingPrices) {
                    const prices = Object.entries(this.shippingPrices)
                        .filter(([key, value]) =>
                            !isNaN(parseFloat(value)) &&
                            ![
                                "additional_item_price",
                                "updated_at",
                                "created_at",
                                "id"
                            ].includes(key)
                        )
                        .map(([key, value]) => {
                            let label = this.format_from_db(key);
                            let desc = '';
                            switch (key) {
                                case "standard":
                                    desc = this.$tc('shipping.standard_description');
                                    break;
                                case "standard_tracked":
                                    desc = this.$tc('shipping.standard_tracked_description');
                                    break;
                                case "express":
                                    desc =  this.$tc('shipping.express_description');
                                    break;
                                default:
                                    break;
                            }

                            return {
                                label,
                                desc,
                                value: value
                            };

                        });
                    this.selectedShippingPrice = prices[0];
                    return prices;
                }
                return [];
            },
            additionalItemCost() {
                if (this.shippingPrices)
                {
                    const additionalItemPrice = Object.entries(this.shippingPrices)
                        .find(([key]) => key === "additional_item_price");

                    let additionalPrice = parseFloat(additionalItemPrice[1]);

                    return additionalPrice.toFixed(2);
                }

                return 0;
            },
            totalAdditionalItemCost() {
                let output = 0;

                this.$store.state.basket.products.forEach(data => {
                    output += (parseInt(data.quantity) * this.additionalItemCost);
                });
                //takes one additional item cost away
                output -= this.additionalItemCost;

                return output;
            }
        },
        methods: {
            /**
             * Populates the preparedRequest object with data from the basket.
             * @param {Object} preparedRequest - The object to be populated with basket data.
             * @returns {undefined} The object is passed by reference and reassignment is not necessary.
             */
            addBasketDataToPreparedRequest(preparedRequest) {
                // Convert any base64 images to blobs.
                this.$store.dispatch('basket/convertBase64ImagesToBlobs');

                // Iterate over each product in the basket and add its data to preparedRequest.
                this.$store.state.basket.products.forEach((product) => {
                    const productData = {
                        description: product.description,
                        name: product.name,
                        price: product.price,
                        product_id: product.product_id,
                        quantity: product.quantity,
                        status: product.status,
                        store_default_vat: product.store_default_vat,
                        store_selling_price: product.store_selling_price,
                        custom_text_front: product.products.front.some(canvasObject => canvasObject.customisable),
                        custom_text_back: product.products.back.some(canvasObject => canvasObject.customisable),
                        products: product.products,
                    };

                    preparedRequest.products.push(productData);
                });

            },
            createPaymentIntent() {
                this.loading = true;

                this.createIntentStep = false;
                this.formErrors = [];

                this.$axios.post(`api/${this.$store.state.tenant.tenant}${this.$route.path}/pay`, {
                    amount: this.totalPriceWithShipping,
                    discount_code: this.enteredVoucherCode,
                    products: this.$store.state.basket.products,
                    customer_id: this.customerId,
                    shipping_method: this.format_to_db(this.selectedShippingPrice.label),
                    total_shipping_price: this.totalShippingPrice
                }).then(({ data }) => {
                    // here we need to skip stripe if it doesn't cost anything
                    if (data?.order_fully_paid_by_voucher) {
                        this.order = data.order;
                        this.noPaymentNeeded();
                    }
                    else {
                        this.elementsOptions.clientSecret = data.client_secret;
                        this.stripeConnectAccountId = data.tenant_stripe_connect_account_id ? data.tenant_stripe_connect_account_id : undefined;
                        if (this.$store.state.event.region === 'US') {
                            this.basketTaxBreakdown = data.basket_tax_breakdown;
                        }
                        else {
                            this.showPaymentStep = true;
                        }

                    }

                }).catch(error => {
                    console.error(error.response?.data?.errors);
                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;

                        // Transform errors from array format to object format
                        this.formErrors = Object.fromEntries(Object.entries(errors).map(([key, value]) => [key, value[0]]));
                    } else {
                        console.error(error);
                    }
                    this.createIntentStep = true;
                }).finally(() => {
                    this.loading = false;
                });
            },
            payWithStripe() {
                this.loading = true;

                this.$refs.paymentRef.submit();
            },
            handleStripeError(error) {
                console.log(error);

                this.loading = false
            },
            /**
             * Handles the successful Stripe payment process.
             * @param {Object} paymentIntent - The payment intent object returned by Stripe
             */
            handleStripeConfirmed(paymentIntent) {
                this.loading = true;
                this.showPaymentStep = false;

                // Calculate estimated delivery dates.
                const startDate = moment();
                const estimatedDeliveryDateX = this.addBusinessDaysToDate(startDate, 4).format('DD-MM-YYYY');
                const estimatedDeliveryDateY = this.addBusinessDaysToDate(startDate, 6).format('DD-MM-YYYY');

                // Prepare the initial request object.
                const preparedRequest = {
                    payment_intent: paymentIntent,
                    estimatedDeliveryDateX,
                    estimatedDeliveryDateY,
                    products: []
                };

                // Populate preparedRequest with basket data.
                this.addBasketDataToPreparedRequest(preparedRequest);

                // Convert the preparedRequest object to FormData.
                const formData = new FormData();
                this.appendNestedDataToFormData(formData, preparedRequest);

                this.$axios.post(`api/${this.$store.state.tenant.tenant}${this.$route.path}/payment-success`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(async ({ data }) => {
                    await this.$store.dispatch('basket/convertBlobsToBase64Images');
                    this.order = data.data.order;
                    this.products = this.$store.state.basket.products;
                    this.shippingMethod = this.format_to_db(this.selectedShippingPrice.label.replace(/_/g, ' '));

                    this.showConfirmStep = true;
                    this.$store.commit("basket/CLEAR_PRODUCTS");
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
            },
            postCustomerInfo(val) {
                this.form = val;
                this.formErrors = [];

                this.$axios.post(`api/${this.$store.state.tenant.tenant}${this.$route.path}/create-customer`, {
                    ...this.form,
                    lang: this.$i18n.locale,
                    products: this.$store.state.basket.products
                }).then(({ data }) => {
                    this.preparePrefilledAddress(data.data.country_iso2, data.data.state_abbreviation);
                    this.customerId = data.data.customer_id;
                    this.shippingPrices = data.data.shipping_prices;
                    this.createIntentStep = true;
                    this.addressStep = false;
                    this.vouchers = data.data.event_vouchers,
                    this.hasVoucherOpportunity = data.data.has_voucher_opportunity
                }).catch(error => {
                    if (error.response?.data?.errors) {
                        const errors = error.response.data.errors;

                        this.formErrors.push(...Object.entries(errors));
                    } else {
                        console.error(error);
                    }
                });
            },
            preparePrefilledAddress(country_iso2, state) {
                this.prefilledAddress.line1 = this.form.address_first_line;
                this.prefilledAddress.line2 = this.form.address_second_line;
                this.prefilledAddress.city = this.form.address_city;
                this.prefilledAddress.state = state;
                this.prefilledAddress.postal_code = this.form.address_postcode;
                this.prefilledAddress.country = country_iso2;
            },
            getStripePublishableKeyByRegion() {
                if (this.$store.state.event.region === 'UK') {
                    return this.$config.stripePublishableKey;
                }

                if (this.$store.state.event.region === 'EU') {
                    return this.$config.stripeEUPublishableKey;
                }

                if (this.$store.state.event.region === 'US') {
                    return this.$config.stripeUSPublishableKey;
                }

                return null;
            },
            combinedShippingCostDisplay(item, additionalCost) {
                let output = (parseFloat(item) + additionalCost);
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(vat) + parseFloat(output)).toFixed(2);
            },
            combinedShippingCostDisplayVat(item, additionalCost) {
                let output = (parseFloat(item) + additionalCost);
                return (output * this.$store.state.event.vatRate / 100).toFixed(2);
            },
            combinedShippingCost(item, additionalCost) {
                let output = (parseFloat(item) + additionalCost);
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(vat) + parseFloat(output));
            },
            combinedShippingCostNoVat(item, additionalCost) {
                return (parseFloat(item) + additionalCost);
            },
            voucherCodeUpdate({ value }) {
                this.enteredVoucherCode = value;
            },
            getErrorMessages(key) {
                return this.formErrors[key];
            },
            hasError(key) {
                return !!this.formErrors[key];
            },
            noPaymentNeeded() {
                this.loading = true;
                this.showPaymentStep = false;

                // Calculate estimated delivery dates.
                const startDate = moment();
                const estimatedDeliveryDateX = this.addBusinessDaysToDate(startDate, 4).format('DD-MM-YYYY');
                const estimatedDeliveryDateY = this.addBusinessDaysToDate(startDate, 6).format('DD-MM-YYYY');

                // Prepare the initial request object.
                const preparedRequest = {
                    no_payment_needed: true,
                    order_id: this.order.id,
                    estimatedDeliveryDateX,
                    estimatedDeliveryDateY,
                    products: []
                };

                // Populate preparedRequest with basket data.
                this.addBasketDataToPreparedRequest(preparedRequest);

                // Convert the preparedRequest object to FormData.
                const formData = new FormData();
                this.appendNestedDataToFormData(formData, preparedRequest);
                // why does this 404??
                this.$axios.post(`api/${this.$store.state.tenant.tenant}${this.$route.path}/payment-success`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(async ({ data }) => {
                        await this.$store.dispatch('basket/convertBlobsToBase64Images');
                        this.order = data.data.order;
                        this.products = this.$store.state.basket.products;
                        this.shippingMethod = this.format_to_db(this.selectedShippingPrice.label.replace(/_/g, ' '));

                        this.showConfirmStep = true;
                        this.$store.commit("basket/CLEAR_PRODUCTS");
                    })
                    .catch(error => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        }
    }
