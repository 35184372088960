export const AdminHeader = () => import('../../components/admin/header.vue' /* webpackChunkName: "components/admin-header" */).then(c => wrapFunctional(c.default || c))
export const AdminNav = () => import('../../components/admin/nav.vue' /* webpackChunkName: "components/admin-nav" */).then(c => wrapFunctional(c.default || c))
export const AdminSuperNav = () => import('../../components/admin/super-nav.vue' /* webpackChunkName: "components/admin-super-nav" */).then(c => wrapFunctional(c.default || c))
export const AdminTitle = () => import('../../components/admin/title.vue' /* webpackChunkName: "components/admin-title" */).then(c => wrapFunctional(c.default || c))
export const AkeneoCard = () => import('../../components/akeneo/card.vue' /* webpackChunkName: "components/akeneo-card" */).then(c => wrapFunctional(c.default || c))
export const AkeneoProducts = () => import('../../components/akeneo/products.vue' /* webpackChunkName: "components/akeneo-products" */).then(c => wrapFunctional(c.default || c))
export const AssetAssets = () => import('../../components/asset/AssetAssets.vue' /* webpackChunkName: "components/asset-assets" */).then(c => wrapFunctional(c.default || c))
export const AssetDesign = () => import('../../components/asset/AssetDesign.vue' /* webpackChunkName: "components/asset-design" */).then(c => wrapFunctional(c.default || c))
export const AssetFixed = () => import('../../components/asset/AssetFixed.vue' /* webpackChunkName: "components/asset-fixed" */).then(c => wrapFunctional(c.default || c))
export const AssetLayers = () => import('../../components/asset/AssetLayers.vue' /* webpackChunkName: "components/asset-layers" */).then(c => wrapFunctional(c.default || c))
export const AssetPick = () => import('../../components/asset/AssetPick.vue' /* webpackChunkName: "components/asset-pick" */).then(c => wrapFunctional(c.default || c))
export const AssetProduct = () => import('../../components/asset/AssetProduct.vue' /* webpackChunkName: "components/asset-product" */).then(c => wrapFunctional(c.default || c))
export const AssetText = () => import('../../components/asset/AssetText.vue' /* webpackChunkName: "components/asset-text" */).then(c => wrapFunctional(c.default || c))
export const AssetsEdit = () => import('../../components/assets/edit.vue' /* webpackChunkName: "components/assets-edit" */).then(c => wrapFunctional(c.default || c))
export const BannerIncomplete = () => import('../../components/banner/incomplete.vue' /* webpackChunkName: "components/banner-incomplete" */).then(c => wrapFunctional(c.default || c))
export const BannerSteps = () => import('../../components/banner/steps.vue' /* webpackChunkName: "components/banner-steps" */).then(c => wrapFunctional(c.default || c))
export const BasketDropdown = () => import('../../components/basket/BasketDropdown.vue' /* webpackChunkName: "components/basket-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BillingReconciliationViewUs = () => import('../../components/billingReconciliation/viewUs.vue' /* webpackChunkName: "components/billing-reconciliation-view-us" */).then(c => wrapFunctional(c.default || c))
export const BuilderImageSelect = () => import('../../components/builder/image-select.vue' /* webpackChunkName: "components/builder-image-select" */).then(c => wrapFunctional(c.default || c))
export const BuilderStep1 = () => import('../../components/builder/step1.vue' /* webpackChunkName: "components/builder-step1" */).then(c => wrapFunctional(c.default || c))
export const BuilderStep2 = () => import('../../components/builder/step2.vue' /* webpackChunkName: "components/builder-step2" */).then(c => wrapFunctional(c.default || c))
export const BuilderStep3 = () => import('../../components/builder/step3.vue' /* webpackChunkName: "components/builder-step3" */).then(c => wrapFunctional(c.default || c))
export const ButtonDefault = () => import('../../components/button/Default.vue' /* webpackChunkName: "components/button-default" */).then(c => wrapFunctional(c.default || c))
export const ButtonDelete = () => import('../../components/button/Delete.vue' /* webpackChunkName: "components/button-delete" */).then(c => wrapFunctional(c.default || c))
export const ButtonIcon = () => import('../../components/button/Icon.vue' /* webpackChunkName: "components/button-icon" */).then(c => wrapFunctional(c.default || c))
export const ButtonModal = () => import('../../components/button/Modal.vue' /* webpackChunkName: "components/button-modal" */).then(c => wrapFunctional(c.default || c))
export const ButtonToggle = () => import('../../components/button/Toggle.vue' /* webpackChunkName: "components/button-toggle" */).then(c => wrapFunctional(c.default || c))
export const ColorGallery = () => import('../../components/color/gallery.vue' /* webpackChunkName: "components/color-gallery" */).then(c => wrapFunctional(c.default || c))
export const ControlSite = () => import('../../components/control/ControlSite.vue' /* webpackChunkName: "components/control-site" */).then(c => wrapFunctional(c.default || c))
export const CreatorArea = () => import('../../components/creator/CreatorArea.vue' /* webpackChunkName: "components/creator-area" */).then(c => wrapFunctional(c.default || c))
export const CreatorAssets = () => import('../../components/creator/CreatorAssets.vue' /* webpackChunkName: "components/creator-assets" */).then(c => wrapFunctional(c.default || c))
export const CreatorCanvas = () => import('../../components/creator/CreatorCanvas.vue' /* webpackChunkName: "components/creator-canvas" */).then(c => wrapFunctional(c.default || c))
export const CreatorCanvasEditor = () => import('../../components/creator/CreatorCanvasEditor.vue' /* webpackChunkName: "components/creator-canvas-editor" */).then(c => wrapFunctional(c.default || c))
export const CreatorCanvasPreview = () => import('../../components/creator/CreatorCanvasPreview.vue' /* webpackChunkName: "components/creator-canvas-preview" */).then(c => wrapFunctional(c.default || c))
export const CreatorProductColours = () => import('../../components/creator/CreatorProductColours.vue' /* webpackChunkName: "components/creator-product-colours" */).then(c => wrapFunctional(c.default || c))
export const CreatorProductSizes = () => import('../../components/creator/CreatorProductSizes.vue' /* webpackChunkName: "components/creator-product-sizes" */).then(c => wrapFunctional(c.default || c))
export const DashboardActionCard = () => import('../../components/dashboard/action-card.vue' /* webpackChunkName: "components/dashboard-action-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardHelpCard = () => import('../../components/dashboard/help-card.vue' /* webpackChunkName: "components/dashboard-help-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardOverview = () => import('../../components/dashboard/overview.vue' /* webpackChunkName: "components/dashboard-overview" */).then(c => wrapFunctional(c.default || c))
export const DashboardRecentOrdersCard = () => import('../../components/dashboard/recent-orders-card.vue' /* webpackChunkName: "components/dashboard-recent-orders-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardShippingPricesCard = () => import('../../components/dashboard/shipping-prices-card.vue' /* webpackChunkName: "components/dashboard-shipping-prices-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardStatCard = () => import('../../components/dashboard/stat-card.vue' /* webpackChunkName: "components/dashboard-stat-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardStats = () => import('../../components/dashboard/stats.vue' /* webpackChunkName: "components/dashboard-stats" */).then(c => wrapFunctional(c.default || c))
export const DashboardTaskOrAction = () => import('../../components/dashboard/task-or-action.vue' /* webpackChunkName: "components/dashboard-task-or-action" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopProductsAndRecentOrdersCards = () => import('../../components/dashboard/top-products-and-recent-orders-cards.vue' /* webpackChunkName: "components/dashboard-top-products-and-recent-orders-cards" */).then(c => wrapFunctional(c.default || c))
export const DashboardTopProductsCard = () => import('../../components/dashboard/top-products-card.vue' /* webpackChunkName: "components/dashboard-top-products-card" */).then(c => wrapFunctional(c.default || c))
export const DemoProducts = () => import('../../components/demo/products.vue' /* webpackChunkName: "components/demo-products" */).then(c => wrapFunctional(c.default || c))
export const DisplayProductImageConsumerStoreAndCart = () => import('../../components/display/ProductImageConsumerStoreAndCart.vue' /* webpackChunkName: "components/display-product-image-consumer-store-and-cart" */).then(c => wrapFunctional(c.default || c))
export const DisplayProductImageStoreBackendAndDashboard = () => import('../../components/display/ProductImageStoreBackendAndDashboard.vue' /* webpackChunkName: "components/display-product-image-store-backend-and-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DisplayProductImageBasket = () => import('../../components/display/product-image-basket.vue' /* webpackChunkName: "components/display-product-image-basket" */).then(c => wrapFunctional(c.default || c))
export const DisplayProductImageTwo = () => import('../../components/display/product-image-two.vue' /* webpackChunkName: "components/display-product-image-two" */).then(c => wrapFunctional(c.default || c))
export const ErrorAuth = () => import('../../components/error/auth.vue' /* webpackChunkName: "components/error-auth" */).then(c => wrapFunctional(c.default || c))
export const ErrorForbidden = () => import('../../components/error/forbidden.vue' /* webpackChunkName: "components/error-forbidden" */).then(c => wrapFunctional(c.default || c))
export const ErrorNot = () => import('../../components/error/not.vue' /* webpackChunkName: "components/error-not" */).then(c => wrapFunctional(c.default || c))
export const ErrorServer = () => import('../../components/error/server.vue' /* webpackChunkName: "components/error-server" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/event/card.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventEdit = () => import('../../components/event/edit.vue' /* webpackChunkName: "components/event-edit" */).then(c => wrapFunctional(c.default || c))
export const EventVoucherEdit = () => import('../../components/eventVoucher/edit.vue' /* webpackChunkName: "components/event-voucher-edit" */).then(c => wrapFunctional(c.default || c))
export const FontGallery = () => import('../../components/font/gallery.vue' /* webpackChunkName: "components/font-gallery" */).then(c => wrapFunctional(c.default || c))
export const FooterFour = () => import('../../components/footer/FooterFour.vue' /* webpackChunkName: "components/footer-four" */).then(c => wrapFunctional(c.default || c))
export const FooterOne = () => import('../../components/footer/FooterOne.vue' /* webpackChunkName: "components/footer-one" */).then(c => wrapFunctional(c.default || c))
export const FooterThree = () => import('../../components/footer/FooterThree.vue' /* webpackChunkName: "components/footer-three" */).then(c => wrapFunctional(c.default || c))
export const FooterTwo = () => import('../../components/footer/FooterTwo.vue' /* webpackChunkName: "components/footer-two" */).then(c => wrapFunctional(c.default || c))
export const FormArea = () => import('../../components/form/FormArea.vue' /* webpackChunkName: "components/form-area" */).then(c => wrapFunctional(c.default || c))
export const FormCheck = () => import('../../components/form/FormCheck.vue' /* webpackChunkName: "components/form-check" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/form/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormInputAlt = () => import('../../components/form/FormInputAlt.vue' /* webpackChunkName: "components/form-input-alt" */).then(c => wrapFunctional(c.default || c))
export const FormInputDomain = () => import('../../components/form/FormInputDomain.vue' /* webpackChunkName: "components/form-input-domain" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/form/FormSelect.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormConsumer = () => import('../../components/form/consumer.vue' /* webpackChunkName: "components/form-consumer" */).then(c => wrapFunctional(c.default || c))
export const FormCustomSelect = () => import('../../components/form/customSelect.vue' /* webpackChunkName: "components/form-custom-select" */).then(c => wrapFunctional(c.default || c))
export const FormShipping = () => import('../../components/form/shipping.vue' /* webpackChunkName: "components/form-shipping" */).then(c => wrapFunctional(c.default || c))
export const GalleryCarousel = () => import('../../components/gallery/GalleryCarousel.vue' /* webpackChunkName: "components/gallery-carousel" */).then(c => wrapFunctional(c.default || c))
export const GalleryShopOne = () => import('../../components/gallery/GalleryShopOne.vue' /* webpackChunkName: "components/gallery-shop-one" */).then(c => wrapFunctional(c.default || c))
export const GalleryShopThree = () => import('../../components/gallery/GalleryShopThree.vue' /* webpackChunkName: "components/gallery-shop-three" */).then(c => wrapFunctional(c.default || c))
export const GalleryShopTwo = () => import('../../components/gallery/GalleryShopTwo.vue' /* webpackChunkName: "components/gallery-shop-two" */).then(c => wrapFunctional(c.default || c))
export const GalleryColor = () => import('../../components/gallery/color.vue' /* webpackChunkName: "components/gallery-color" */).then(c => wrapFunctional(c.default || c))
export const GalleryFont = () => import('../../components/gallery/font.vue' /* webpackChunkName: "components/gallery-font" */).then(c => wrapFunctional(c.default || c))
export const GalleryImage = () => import('../../components/gallery/image.vue' /* webpackChunkName: "components/gallery-image" */).then(c => wrapFunctional(c.default || c))
export const GetStarted = () => import('../../components/get/started.vue' /* webpackChunkName: "components/get-started" */).then(c => wrapFunctional(c.default || c))
export const HeaderOne = () => import('../../components/header/HeaderOne.vue' /* webpackChunkName: "components/header-one" */).then(c => wrapFunctional(c.default || c))
export const HeaderThree = () => import('../../components/header/HeaderThree.vue' /* webpackChunkName: "components/header-three" */).then(c => wrapFunctional(c.default || c))
export const HeaderTwo = () => import('../../components/header/HeaderTwo.vue' /* webpackChunkName: "components/header-two" */).then(c => wrapFunctional(c.default || c))
export const HeroOne = () => import('../../components/hero/HeroOne.vue' /* webpackChunkName: "components/hero-one" */).then(c => wrapFunctional(c.default || c))
export const HeroThree = () => import('../../components/hero/HeroThree.vue' /* webpackChunkName: "components/hero-three" */).then(c => wrapFunctional(c.default || c))
export const HeroTwo = () => import('../../components/hero/HeroTwo.vue' /* webpackChunkName: "components/hero-two" */).then(c => wrapFunctional(c.default || c))
export const HeroMain = () => import('../../components/hero/main.vue' /* webpackChunkName: "components/hero-main" */).then(c => wrapFunctional(c.default || c))
export const IconAddNewUser = () => import('../../components/icon/IconAddNewUser.vue' /* webpackChunkName: "components/icon-add-new-user" */).then(c => wrapFunctional(c.default || c))
export const IconAdjustmentsHorizontal = () => import('../../components/icon/IconAdjustmentsHorizontal.vue' /* webpackChunkName: "components/icon-adjustments-horizontal" */).then(c => wrapFunctional(c.default || c))
export const IconAdminShield = () => import('../../components/icon/IconAdminShield.vue' /* webpackChunkName: "components/icon-admin-shield" */).then(c => wrapFunctional(c.default || c))
export const IconAlignTop = () => import('../../components/icon/IconAlignTop.vue' /* webpackChunkName: "components/icon-align-top" */).then(c => wrapFunctional(c.default || c))
export const IconAlignVertical = () => import('../../components/icon/IconAlignVertical.vue' /* webpackChunkName: "components/icon-align-vertical" */).then(c => wrapFunctional(c.default || c))
export const IconArrow = () => import('../../components/icon/IconArrow.vue' /* webpackChunkName: "components/icon-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft2 = () => import('../../components/icon/IconArrowLeft2.vue' /* webpackChunkName: "components/icon-arrow-left2" */).then(c => wrapFunctional(c.default || c))
export const IconArrowPathRounded = () => import('../../components/icon/IconArrowPathRounded.vue' /* webpackChunkName: "components/icon-arrow-path-rounded" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRight = () => import('../../components/icon/IconArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c))
export const IconArrowTopRight = () => import('../../components/icon/IconArrowTopRight.vue' /* webpackChunkName: "components/icon-arrow-top-right" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUpDown = () => import('../../components/icon/IconArrowUpDown.vue' /* webpackChunkName: "components/icon-arrow-up-down" */).then(c => wrapFunctional(c.default || c))
export const IconBack = () => import('../../components/icon/IconBack.vue' /* webpackChunkName: "components/icon-back" */).then(c => wrapFunctional(c.default || c))
export const IconBasket = () => import('../../components/icon/IconBasket.vue' /* webpackChunkName: "components/icon-basket" */).then(c => wrapFunctional(c.default || c))
export const IconBold = () => import('../../components/icon/IconBold.vue' /* webpackChunkName: "components/icon-bold" */).then(c => wrapFunctional(c.default || c))
export const IconBranding = () => import('../../components/icon/IconBranding.vue' /* webpackChunkName: "components/icon-branding" */).then(c => wrapFunctional(c.default || c))
export const IconBuildingOffice = () => import('../../components/icon/IconBuildingOffice.vue' /* webpackChunkName: "components/icon-building-office" */).then(c => wrapFunctional(c.default || c))
export const IconCheckCircle = () => import('../../components/icon/IconCheckCircle.vue' /* webpackChunkName: "components/icon-check-circle" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown2 = () => import('../../components/icon/IconChevronDown2.vue' /* webpackChunkName: "components/icon-chevron-down2" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../components/icon/IconClose.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconCloseAlt = () => import('../../components/icon/IconCloseAlt.vue' /* webpackChunkName: "components/icon-close-alt" */).then(c => wrapFunctional(c.default || c))
export const IconCopy = () => import('../../components/icon/IconCopy.vue' /* webpackChunkName: "components/icon-copy" */).then(c => wrapFunctional(c.default || c))
export const IconCreditCard = () => import('../../components/icon/IconCreditCard.vue' /* webpackChunkName: "components/icon-credit-card" */).then(c => wrapFunctional(c.default || c))
export const IconCreditCardAlt = () => import('../../components/icon/IconCreditCardAlt.vue' /* webpackChunkName: "components/icon-credit-card-alt" */).then(c => wrapFunctional(c.default || c))
export const IconCrossMini = () => import('../../components/icon/IconCrossMini.vue' /* webpackChunkName: "components/icon-cross-mini" */).then(c => wrapFunctional(c.default || c))
export const IconDelete = () => import('../../components/icon/IconDelete.vue' /* webpackChunkName: "components/icon-delete" */).then(c => wrapFunctional(c.default || c))
export const IconDomainPreview = () => import('../../components/icon/IconDomainPreview.vue' /* webpackChunkName: "components/icon-domain-preview" */).then(c => wrapFunctional(c.default || c))
export const IconDown = () => import('../../components/icon/IconDown.vue' /* webpackChunkName: "components/icon-down" */).then(c => wrapFunctional(c.default || c))
export const IconDownTray = () => import('../../components/icon/IconDownTray.vue' /* webpackChunkName: "components/icon-down-tray" */).then(c => wrapFunctional(c.default || c))
export const IconDuplicate = () => import('../../components/icon/IconDuplicate.vue' /* webpackChunkName: "components/icon-duplicate" */).then(c => wrapFunctional(c.default || c))
export const IconEdit = () => import('../../components/icon/IconEdit.vue' /* webpackChunkName: "components/icon-edit" */).then(c => wrapFunctional(c.default || c))
export const IconEdit2 = () => import('../../components/icon/IconEdit2.vue' /* webpackChunkName: "components/icon-edit2" */).then(c => wrapFunctional(c.default || c))
export const IconEnlarge = () => import('../../components/icon/IconEnlarge.vue' /* webpackChunkName: "components/icon-enlarge" */).then(c => wrapFunctional(c.default || c))
export const IconEnvelope = () => import('../../components/icon/IconEnvelope.vue' /* webpackChunkName: "components/icon-envelope" */).then(c => wrapFunctional(c.default || c))
export const IconEventDetails = () => import('../../components/icon/IconEventDetails.vue' /* webpackChunkName: "components/icon-event-details" */).then(c => wrapFunctional(c.default || c))
export const IconExclamation = () => import('../../components/icon/IconExclamation.vue' /* webpackChunkName: "components/icon-exclamation" */).then(c => wrapFunctional(c.default || c))
export const IconFaq = () => import('../../components/icon/IconFaq.vue' /* webpackChunkName: "components/icon-faq" */).then(c => wrapFunctional(c.default || c))
export const IconFlagES = () => import('../../components/icon/IconFlagES.vue' /* webpackChunkName: "components/icon-flag-e-s" */).then(c => wrapFunctional(c.default || c))
export const IconFlagIT = () => import('../../components/icon/IconFlagIT.vue' /* webpackChunkName: "components/icon-flag-i-t" */).then(c => wrapFunctional(c.default || c))
export const IconGrid = () => import('../../components/icon/IconGrid.vue' /* webpackChunkName: "components/icon-grid" */).then(c => wrapFunctional(c.default || c))
export const IconGuide = () => import('../../components/icon/IconGuide.vue' /* webpackChunkName: "components/icon-guide" */).then(c => wrapFunctional(c.default || c))
export const IconInfo = () => import('../../components/icon/IconInfo.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconInvite = () => import('../../components/icon/IconInvite.vue' /* webpackChunkName: "components/icon-invite" */).then(c => wrapFunctional(c.default || c))
export const IconItalic = () => import('../../components/icon/IconItalic.vue' /* webpackChunkName: "components/icon-italic" */).then(c => wrapFunctional(c.default || c))
export const IconLeft = () => import('../../components/icon/IconLeft.vue' /* webpackChunkName: "components/icon-left" */).then(c => wrapFunctional(c.default || c))
export const IconLoader = () => import('../../components/icon/IconLoader.vue' /* webpackChunkName: "components/icon-loader" */).then(c => wrapFunctional(c.default || c))
export const IconLoaderNew = () => import('../../components/icon/IconLoaderNew.vue' /* webpackChunkName: "components/icon-loader-new" */).then(c => wrapFunctional(c.default || c))
export const IconLock = () => import('../../components/icon/IconLock.vue' /* webpackChunkName: "components/icon-lock" */).then(c => wrapFunctional(c.default || c))
export const IconLockAlt = () => import('../../components/icon/IconLockAlt.vue' /* webpackChunkName: "components/icon-lock-alt" */).then(c => wrapFunctional(c.default || c))
export const IconLogout = () => import('../../components/icon/IconLogout.vue' /* webpackChunkName: "components/icon-logout" */).then(c => wrapFunctional(c.default || c))
export const IconMagnifyingGlass = () => import('../../components/icon/IconMagnifyingGlass.vue' /* webpackChunkName: "components/icon-magnifying-glass" */).then(c => wrapFunctional(c.default || c))
export const IconMinus = () => import('../../components/icon/IconMinus.vue' /* webpackChunkName: "components/icon-minus" */).then(c => wrapFunctional(c.default || c))
export const IconMinusCircle = () => import('../../components/icon/IconMinusCircle.vue' /* webpackChunkName: "components/icon-minus-circle" */).then(c => wrapFunctional(c.default || c))
export const IconPencil = () => import('../../components/icon/IconPencil.vue' /* webpackChunkName: "components/icon-pencil" */).then(c => wrapFunctional(c.default || c))
export const IconPlus = () => import('../../components/icon/IconPlus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c))
export const IconProducts = () => import('../../components/icon/IconProducts.vue' /* webpackChunkName: "components/icon-products" */).then(c => wrapFunctional(c.default || c))
export const IconProducts2 = () => import('../../components/icon/IconProducts2.vue' /* webpackChunkName: "components/icon-products2" */).then(c => wrapFunctional(c.default || c))
export const IconPublish = () => import('../../components/icon/IconPublish.vue' /* webpackChunkName: "components/icon-publish" */).then(c => wrapFunctional(c.default || c))
export const IconQuestionMarkCircle = () => import('../../components/icon/IconQuestionMarkCircle.vue' /* webpackChunkName: "components/icon-question-mark-circle" */).then(c => wrapFunctional(c.default || c))
export const IconRight = () => import('../../components/icon/IconRight.vue' /* webpackChunkName: "components/icon-right" */).then(c => wrapFunctional(c.default || c))
export const IconSave = () => import('../../components/icon/IconSave.vue' /* webpackChunkName: "components/icon-save" */).then(c => wrapFunctional(c.default || c))
export const IconSearch = () => import('../../components/icon/IconSearch.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c))
export const IconSettings = () => import('../../components/icon/IconSettings.vue' /* webpackChunkName: "components/icon-settings" */).then(c => wrapFunctional(c.default || c))
export const IconShoppingBag = () => import('../../components/icon/IconShoppingBag.vue' /* webpackChunkName: "components/icon-shopping-bag" */).then(c => wrapFunctional(c.default || c))
export const IconShoppingBagAlt = () => import('../../components/icon/IconShoppingBagAlt.vue' /* webpackChunkName: "components/icon-shopping-bag-alt" */).then(c => wrapFunctional(c.default || c))
export const IconShoppingBagOutlined = () => import('../../components/icon/IconShoppingBagOutlined.vue' /* webpackChunkName: "components/icon-shopping-bag-outlined" */).then(c => wrapFunctional(c.default || c))
export const IconShoppingCart = () => import('../../components/icon/IconShoppingCart.vue' /* webpackChunkName: "components/icon-shopping-cart" */).then(c => wrapFunctional(c.default || c))
export const IconStoreDesigner = () => import('../../components/icon/IconStoreDesigner.vue' /* webpackChunkName: "components/icon-store-designer" */).then(c => wrapFunctional(c.default || c))
export const IconStoreOverview = () => import('../../components/icon/IconStoreOverview.vue' /* webpackChunkName: "components/icon-store-overview" */).then(c => wrapFunctional(c.default || c))
export const IconStores = () => import('../../components/icon/IconStores.vue' /* webpackChunkName: "components/icon-stores" */).then(c => wrapFunctional(c.default || c))
export const IconTab = () => import('../../components/icon/IconTab.vue' /* webpackChunkName: "components/icon-tab" */).then(c => wrapFunctional(c.default || c))
export const IconText = () => import('../../components/icon/IconText.vue' /* webpackChunkName: "components/icon-text" */).then(c => wrapFunctional(c.default || c))
export const IconTick = () => import('../../components/icon/IconTick.vue' /* webpackChunkName: "components/icon-tick" */).then(c => wrapFunctional(c.default || c))
export const IconTickMini = () => import('../../components/icon/IconTickMini.vue' /* webpackChunkName: "components/icon-tick-mini" */).then(c => wrapFunctional(c.default || c))
export const IconTrash = () => import('../../components/icon/IconTrash.vue' /* webpackChunkName: "components/icon-trash" */).then(c => wrapFunctional(c.default || c))
export const IconTrashAlt = () => import('../../components/icon/IconTrashAlt.vue' /* webpackChunkName: "components/icon-trash-alt" */).then(c => wrapFunctional(c.default || c))
export const IconTrashWhite = () => import('../../components/icon/IconTrashWhite.vue' /* webpackChunkName: "components/icon-trash-white" */).then(c => wrapFunctional(c.default || c))
export const IconUkFlag = () => import('../../components/icon/IconUkFlag.vue' /* webpackChunkName: "components/icon-uk-flag" */).then(c => wrapFunctional(c.default || c))
export const IconUnderline = () => import('../../components/icon/IconUnderline.vue' /* webpackChunkName: "components/icon-underline" */).then(c => wrapFunctional(c.default || c))
export const IconUnlock = () => import('../../components/icon/IconUnlock.vue' /* webpackChunkName: "components/icon-unlock" */).then(c => wrapFunctional(c.default || c))
export const IconUnpublish = () => import('../../components/icon/IconUnpublish.vue' /* webpackChunkName: "components/icon-unpublish" */).then(c => wrapFunctional(c.default || c))
export const IconUp = () => import('../../components/icon/IconUp.vue' /* webpackChunkName: "components/icon-up" */).then(c => wrapFunctional(c.default || c))
export const IconUpload = () => import('../../components/icon/IconUpload.vue' /* webpackChunkName: "components/icon-upload" */).then(c => wrapFunctional(c.default || c))
export const IconView = () => import('../../components/icon/IconView.vue' /* webpackChunkName: "components/icon-view" */).then(c => wrapFunctional(c.default || c))
export const IconWorld = () => import('../../components/icon/IconWorld.vue' /* webpackChunkName: "components/icon-world" */).then(c => wrapFunctional(c.default || c))
export const IconAdd = () => import('../../components/icon/add.vue' /* webpackChunkName: "components/icon-add" */).then(c => wrapFunctional(c.default || c))
export const IconAdd2 = () => import('../../components/icon/add2.vue' /* webpackChunkName: "components/icon-add2" */).then(c => wrapFunctional(c.default || c))
export const IconAlignBottom = () => import('../../components/icon/align-bottom.vue' /* webpackChunkName: "components/icon-align-bottom" */).then(c => wrapFunctional(c.default || c))
export const IconAlignCenterText = () => import('../../components/icon/align-center-text.vue' /* webpackChunkName: "components/icon-align-center-text" */).then(c => wrapFunctional(c.default || c))
export const IconAlignHorizontal = () => import('../../components/icon/align-horizontal.vue' /* webpackChunkName: "components/icon-align-horizontal" */).then(c => wrapFunctional(c.default || c))
export const IconAlignLeftText = () => import('../../components/icon/align-left-text.vue' /* webpackChunkName: "components/icon-align-left-text" */).then(c => wrapFunctional(c.default || c))
export const IconAlignLeft = () => import('../../components/icon/align-left.vue' /* webpackChunkName: "components/icon-align-left" */).then(c => wrapFunctional(c.default || c))
export const IconAlignRightText = () => import('../../components/icon/align-right-text.vue' /* webpackChunkName: "components/icon-align-right-text" */).then(c => wrapFunctional(c.default || c))
export const IconAlignRight = () => import('../../components/icon/align-right.vue' /* webpackChunkName: "components/icon-align-right" */).then(c => wrapFunctional(c.default || c))
export const IconArrowDown = () => import('../../components/icon/arrow-down.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c))
export const IconArrowLeft = () => import('../../components/icon/arrow-left.vue' /* webpackChunkName: "components/icon-arrow-left" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRightMini = () => import('../../components/icon/arrow-right-mini.vue' /* webpackChunkName: "components/icon-arrow-right-mini" */).then(c => wrapFunctional(c.default || c))
export const IconArrowRightPoint = () => import('../../components/icon/arrow-right-point.vue' /* webpackChunkName: "components/icon-arrow-right-point" */).then(c => wrapFunctional(c.default || c))
export const IconArrowUp = () => import('../../components/icon/arrow-up.vue' /* webpackChunkName: "components/icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconAssets = () => import('../../components/icon/assets.vue' /* webpackChunkName: "components/icon-assets" */).then(c => wrapFunctional(c.default || c))
export const IconBasketSmall = () => import('../../components/icon/basket-small.vue' /* webpackChunkName: "components/icon-basket-small" */).then(c => wrapFunctional(c.default || c))
export const IconBin = () => import('../../components/icon/bin.vue' /* webpackChunkName: "components/icon-bin" */).then(c => wrapFunctional(c.default || c))
export const IconBuilderHand = () => import('../../components/icon/builder-hand.vue' /* webpackChunkName: "components/icon-builder-hand" */).then(c => wrapFunctional(c.default || c))
export const IconBuilderImage = () => import('../../components/icon/builder-image.vue' /* webpackChunkName: "components/icon-builder-image" */).then(c => wrapFunctional(c.default || c))
export const IconBuilderMouse = () => import('../../components/icon/builder-mouse.vue' /* webpackChunkName: "components/icon-builder-mouse" */).then(c => wrapFunctional(c.default || c))
export const IconBuilderPalette = () => import('../../components/icon/builder-palette.vue' /* webpackChunkName: "components/icon-builder-palette" */).then(c => wrapFunctional(c.default || c))
export const IconBullets = () => import('../../components/icon/bullets.vue' /* webpackChunkName: "components/icon-bullets" */).then(c => wrapFunctional(c.default || c))
export const IconCalender = () => import('../../components/icon/calender.vue' /* webpackChunkName: "components/icon-calender" */).then(c => wrapFunctional(c.default || c))
export const IconCash = () => import('../../components/icon/cash.vue' /* webpackChunkName: "components/icon-cash" */).then(c => wrapFunctional(c.default || c))
export const IconCenter = () => import('../../components/icon/center.vue' /* webpackChunkName: "components/icon-center" */).then(c => wrapFunctional(c.default || c))
export const IconChatBubble = () => import('../../components/icon/chat-bubble.vue' /* webpackChunkName: "components/icon-chat-bubble" */).then(c => wrapFunctional(c.default || c))
export const IconChevronDown = () => import('../../components/icon/chevron-down.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconChevronLeft = () => import('../../components/icon/chevron-left.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconChevronRight = () => import('../../components/icon/chevron-right.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconChevronUp = () => import('../../components/icon/chevron-up.vue' /* webpackChunkName: "components/icon-chevron-up" */).then(c => wrapFunctional(c.default || c))
export const IconCloseTwo = () => import('../../components/icon/close-two.vue' /* webpackChunkName: "components/icon-close-two" */).then(c => wrapFunctional(c.default || c))
export const IconCustomText = () => import('../../components/icon/custom-text.vue' /* webpackChunkName: "components/icon-custom-text" */).then(c => wrapFunctional(c.default || c))
export const IconDash = () => import('../../components/icon/dash.vue' /* webpackChunkName: "components/icon-dash" */).then(c => wrapFunctional(c.default || c))
export const IconDash2 = () => import('../../components/icon/dash2.vue' /* webpackChunkName: "components/icon-dash2" */).then(c => wrapFunctional(c.default || c))
export const IconDashboard = () => import('../../components/icon/dashboard.vue' /* webpackChunkName: "components/icon-dashboard" */).then(c => wrapFunctional(c.default || c))
export const IconDashboard2 = () => import('../../components/icon/dashboard2.vue' /* webpackChunkName: "components/icon-dashboard2" */).then(c => wrapFunctional(c.default || c))
export const IconDonut = () => import('../../components/icon/donut.vue' /* webpackChunkName: "components/icon-donut" */).then(c => wrapFunctional(c.default || c))
export const IconDownload = () => import('../../components/icon/download.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconDrive = () => import('../../components/icon/drive.vue' /* webpackChunkName: "components/icon-drive" */).then(c => wrapFunctional(c.default || c))
export const IconEarth = () => import('../../components/icon/earth.vue' /* webpackChunkName: "components/icon-earth" */).then(c => wrapFunctional(c.default || c))
export const IconEmptyStar = () => import('../../components/icon/empty-star.vue' /* webpackChunkName: "components/icon-empty-star" */).then(c => wrapFunctional(c.default || c))
export const IconExpand = () => import('../../components/icon/expand.vue' /* webpackChunkName: "components/icon-expand" */).then(c => wrapFunctional(c.default || c))
export const IconFixedText = () => import('../../components/icon/fixed-text.vue' /* webpackChunkName: "components/icon-fixed-text" */).then(c => wrapFunctional(c.default || c))
export const IconFont = () => import('../../components/icon/font.vue' /* webpackChunkName: "components/icon-font" */).then(c => wrapFunctional(c.default || c))
export const IconFullStar = () => import('../../components/icon/full-star.vue' /* webpackChunkName: "components/icon-full-star" */).then(c => wrapFunctional(c.default || c))
export const IconHeadphones = () => import('../../components/icon/headphones.vue' /* webpackChunkName: "components/icon-headphones" */).then(c => wrapFunctional(c.default || c))
export const IconBilling = () => import('../../components/icon/iconBilling.vue' /* webpackChunkName: "components/icon-billing" */).then(c => wrapFunctional(c.default || c))
export const IconCheck = () => import('../../components/icon/iconCheck.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c))
export const IconCheckOutline = () => import('../../components/icon/iconCheckOutline.vue' /* webpackChunkName: "components/icon-check-outline" */).then(c => wrapFunctional(c.default || c))
export const IconCompany = () => import('../../components/icon/iconCompany.vue' /* webpackChunkName: "components/icon-company" */).then(c => wrapFunctional(c.default || c))
export const IconCompleted = () => import('../../components/icon/iconCompleted.vue' /* webpackChunkName: "components/icon-completed" */).then(c => wrapFunctional(c.default || c))
export const IconEmail = () => import('../../components/icon/iconEmail.vue' /* webpackChunkName: "components/icon-email" */).then(c => wrapFunctional(c.default || c))
export const IconFlagDA = () => import('../../components/icon/iconFlagDA.vue' /* webpackChunkName: "components/icon-flag-d-a" */).then(c => wrapFunctional(c.default || c))
export const IconFlagDE = () => import('../../components/icon/iconFlagDE.vue' /* webpackChunkName: "components/icon-flag-d-e" */).then(c => wrapFunctional(c.default || c))
export const IconFlagEN = () => import('../../components/icon/iconFlagEN.vue' /* webpackChunkName: "components/icon-flag-e-n" */).then(c => wrapFunctional(c.default || c))
export const IconFlagEU = () => import('../../components/icon/iconFlagEU.vue' /* webpackChunkName: "components/icon-flag-e-u" */).then(c => wrapFunctional(c.default || c))
export const IconFlagFI = () => import('../../components/icon/iconFlagFI.vue' /* webpackChunkName: "components/icon-flag-f-i" */).then(c => wrapFunctional(c.default || c))
export const IconFlagFR = () => import('../../components/icon/iconFlagFR.vue' /* webpackChunkName: "components/icon-flag-f-r" */).then(c => wrapFunctional(c.default || c))
export const IconFlagNL = () => import('../../components/icon/iconFlagNL.vue' /* webpackChunkName: "components/icon-flag-n-l" */).then(c => wrapFunctional(c.default || c))
export const IconFlagNO = () => import('../../components/icon/iconFlagNO.vue' /* webpackChunkName: "components/icon-flag-n-o" */).then(c => wrapFunctional(c.default || c))
export const IconFlagSV = () => import('../../components/icon/iconFlagSV.vue' /* webpackChunkName: "components/icon-flag-s-v" */).then(c => wrapFunctional(c.default || c))
export const IconFlagUK = () => import('../../components/icon/iconFlagUK.vue' /* webpackChunkName: "components/icon-flag-u-k" */).then(c => wrapFunctional(c.default || c))
export const IconLink = () => import('../../components/icon/iconLink.vue' /* webpackChunkName: "components/icon-link" */).then(c => wrapFunctional(c.default || c))
export const IconLogoNew = () => import('../../components/icon/iconLogoNew.vue' /* webpackChunkName: "components/icon-logo-new" */).then(c => wrapFunctional(c.default || c))
export const IconPhone = () => import('../../components/icon/iconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconThreeDotFilled = () => import('../../components/icon/iconThreeDotFilled.vue' /* webpackChunkName: "components/icon-three-dot-filled" */).then(c => wrapFunctional(c.default || c))
export const IconXMark = () => import('../../components/icon/iconXMark.vue' /* webpackChunkName: "components/icon-x-mark" */).then(c => wrapFunctional(c.default || c))
export const IconLayers = () => import('../../components/icon/layers.vue' /* webpackChunkName: "components/icon-layers" */).then(c => wrapFunctional(c.default || c))
export const IconLeaf = () => import('../../components/icon/leaf.vue' /* webpackChunkName: "components/icon-leaf" */).then(c => wrapFunctional(c.default || c))
export const IconLeftview = () => import('../../components/icon/leftview.vue' /* webpackChunkName: "components/icon-leftview" */).then(c => wrapFunctional(c.default || c))
export const IconLetter = () => import('../../components/icon/letter.vue' /* webpackChunkName: "components/icon-letter" */).then(c => wrapFunctional(c.default || c))
export const IconLike = () => import('../../components/icon/like.vue' /* webpackChunkName: "components/icon-like" */).then(c => wrapFunctional(c.default || c))
export const IconLogoBlack = () => import('../../components/icon/logo-black.vue' /* webpackChunkName: "components/icon-logo-black" */).then(c => wrapFunctional(c.default || c))
export const IconLogoLargeBlack = () => import('../../components/icon/logo-large-black.vue' /* webpackChunkName: "components/icon-logo-large-black" */).then(c => wrapFunctional(c.default || c))
export const IconLogoLarge = () => import('../../components/icon/logo-large.vue' /* webpackChunkName: "components/icon-logo-large" */).then(c => wrapFunctional(c.default || c))
export const IconLogoLarger = () => import('../../components/icon/logo-larger.vue' /* webpackChunkName: "components/icon-logo-larger" */).then(c => wrapFunctional(c.default || c))
export const IconLogo = () => import('../../components/icon/logo.vue' /* webpackChunkName: "components/icon-logo" */).then(c => wrapFunctional(c.default || c))
export const IconMenu = () => import('../../components/icon/menu.vue' /* webpackChunkName: "components/icon-menu" */).then(c => wrapFunctional(c.default || c))
export const IconOrdered = () => import('../../components/icon/ordered.vue' /* webpackChunkName: "components/icon-ordered" */).then(c => wrapFunctional(c.default || c))
export const IconPicture = () => import('../../components/icon/picture.vue' /* webpackChunkName: "components/icon-picture" */).then(c => wrapFunctional(c.default || c))
export const IconRightview = () => import('../../components/icon/rightview.vue' /* webpackChunkName: "components/icon-rightview" */).then(c => wrapFunctional(c.default || c))
export const IconShopifyLarge = () => import('../../components/icon/shopify-large.vue' /* webpackChunkName: "components/icon-shopify-large" */).then(c => wrapFunctional(c.default || c))
export const IconShopify = () => import('../../components/icon/shopify.vue' /* webpackChunkName: "components/icon-shopify" */).then(c => wrapFunctional(c.default || c))
export const IconSpinningLoader = () => import('../../components/icon/spinning-loader.vue' /* webpackChunkName: "components/icon-spinning-loader" */).then(c => wrapFunctional(c.default || c))
export const IconStatsFive = () => import('../../components/icon/stats-five.vue' /* webpackChunkName: "components/icon-stats-five" */).then(c => wrapFunctional(c.default || c))
export const IconStatsFour = () => import('../../components/icon/stats-four.vue' /* webpackChunkName: "components/icon-stats-four" */).then(c => wrapFunctional(c.default || c))
export const IconStatsOne = () => import('../../components/icon/stats-one.vue' /* webpackChunkName: "components/icon-stats-one" */).then(c => wrapFunctional(c.default || c))
export const IconStatsThree = () => import('../../components/icon/stats-three.vue' /* webpackChunkName: "components/icon-stats-three" */).then(c => wrapFunctional(c.default || c))
export const IconStatsTwo = () => import('../../components/icon/stats-two.vue' /* webpackChunkName: "components/icon-stats-two" */).then(c => wrapFunctional(c.default || c))
export const IconTextCenter = () => import('../../components/icon/text-center.vue' /* webpackChunkName: "components/icon-text-center" */).then(c => wrapFunctional(c.default || c))
export const IconTextLeft = () => import('../../components/icon/text-left.vue' /* webpackChunkName: "components/icon-text-left" */).then(c => wrapFunctional(c.default || c))
export const IconTextRight = () => import('../../components/icon/text-right.vue' /* webpackChunkName: "components/icon-text-right" */).then(c => wrapFunctional(c.default || c))
export const IconTick2 = () => import('../../components/icon/tick-2.vue' /* webpackChunkName: "components/icon-tick2" */).then(c => wrapFunctional(c.default || c))
export const IconTick3 = () => import('../../components/icon/tick-3.vue' /* webpackChunkName: "components/icon-tick3" */).then(c => wrapFunctional(c.default || c))
export const IconTime = () => import('../../components/icon/time.vue' /* webpackChunkName: "components/icon-time" */).then(c => wrapFunctional(c.default || c))
export const IconTshirtMenu = () => import('../../components/icon/tshirt-menu.vue' /* webpackChunkName: "components/icon-tshirt-menu" */).then(c => wrapFunctional(c.default || c))
export const IconTshirt = () => import('../../components/icon/tshirt.vue' /* webpackChunkName: "components/icon-tshirt" */).then(c => wrapFunctional(c.default || c))
export const IconUserO = () => import('../../components/icon/user-o.vue' /* webpackChunkName: "components/icon-user-o" */).then(c => wrapFunctional(c.default || c))
export const IconUser = () => import('../../components/icon/user.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c))
export const IconVan = () => import('../../components/icon/van.vue' /* webpackChunkName: "components/icon-van" */).then(c => wrapFunctional(c.default || c))
export const ImageGallery = () => import('../../components/image/gallery.vue' /* webpackChunkName: "components/image-gallery" */).then(c => wrapFunctional(c.default || c))
export const ImageGrid = () => import('../../components/image/grid.vue' /* webpackChunkName: "components/image-grid" */).then(c => wrapFunctional(c.default || c))
export const InfiniteScroller = () => import('../../components/infinite/scroller.vue' /* webpackChunkName: "components/infinite-scroller" */).then(c => wrapFunctional(c.default || c))
export const MessageInfo = () => import('../../components/message/info.vue' /* webpackChunkName: "components/message-info" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmCrud = () => import('../../components/modal/ConfirmCrud.vue' /* webpackChunkName: "components/modal-confirm-crud" */).then(c => wrapFunctional(c.default || c))
export const ModalFull = () => import('../../components/modal/ModalFull.vue' /* webpackChunkName: "components/modal-full" */).then(c => wrapFunctional(c.default || c))
export const ModalOverlay = () => import('../../components/modal/ModalOverlay.vue' /* webpackChunkName: "components/modal-overlay" */).then(c => wrapFunctional(c.default || c))
export const ModalPart = () => import('../../components/modal/ModalPart.vue' /* webpackChunkName: "components/modal-part" */).then(c => wrapFunctional(c.default || c))
export const ModalOrderDetailModal = () => import('../../components/modal/OrderDetailModal.vue' /* webpackChunkName: "components/modal-order-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalPartial = () => import('../../components/modal/Partial.vue' /* webpackChunkName: "components/modal-partial" */).then(c => wrapFunctional(c.default || c))
export const ModalSaveOrDiscardChanges = () => import('../../components/modal/SaveOrDiscardChanges.vue' /* webpackChunkName: "components/modal-save-or-discard-changes" */).then(c => wrapFunctional(c.default || c))
export const ModalSomethingWentWrongModal = () => import('../../components/modal/SomethingWentWrongModal.vue' /* webpackChunkName: "components/modal-something-went-wrong-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirm = () => import('../../components/modal/confirm.vue' /* webpackChunkName: "components/modal-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateCsv = () => import('../../components/modal/createCsv.vue' /* webpackChunkName: "components/modal-create-csv" */).then(c => wrapFunctional(c.default || c))
export const ModalCreatorTwo = () => import('../../components/modal/creator-two.vue' /* webpackChunkName: "components/modal-creator-two" */).then(c => wrapFunctional(c.default || c))
export const ModalCreator = () => import('../../components/modal/creator.vue' /* webpackChunkName: "components/modal-creator" */).then(c => wrapFunctional(c.default || c))
export const ModalMessage = () => import('../../components/modal/message.vue' /* webpackChunkName: "components/modal-message" */).then(c => wrapFunctional(c.default || c))
export const ModalPreview = () => import('../../components/modal/preview.vue' /* webpackChunkName: "components/modal-preview" */).then(c => wrapFunctional(c.default || c))
export const OnboardingBillingDetails = () => import('../../components/onboarding/billingDetails.vue' /* webpackChunkName: "components/onboarding-billing-details" */).then(c => wrapFunctional(c.default || c))
export const OnboardingCompanyDetails = () => import('../../components/onboarding/companyDetails.vue' /* webpackChunkName: "components/onboarding-company-details" */).then(c => wrapFunctional(c.default || c))
export const OnboardingConnectShopify = () => import('../../components/onboarding/connectShopify.vue' /* webpackChunkName: "components/onboarding-connect-shopify" */).then(c => wrapFunctional(c.default || c))
export const OnboardingRegisterUser = () => import('../../components/onboarding/registerUser.vue' /* webpackChunkName: "components/onboarding-register-user" */).then(c => wrapFunctional(c.default || c))
export const OnboardingShopifyConnected = () => import('../../components/onboarding/shopifyConnected.vue' /* webpackChunkName: "components/onboarding-shopify-connected" */).then(c => wrapFunctional(c.default || c))
export const OnboardingWizard = () => import('../../components/onboarding/wizard.vue' /* webpackChunkName: "components/onboarding-wizard" */).then(c => wrapFunctional(c.default || c))
export const OrdersStatus = () => import('../../components/orders/status.vue' /* webpackChunkName: "components/orders-status" */).then(c => wrapFunctional(c.default || c))
export const OrdersView = () => import('../../components/orders/view.vue' /* webpackChunkName: "components/orders-view" */).then(c => wrapFunctional(c.default || c))
export const PageEdit = () => import('../../components/page/edit.vue' /* webpackChunkName: "components/page-edit" */).then(c => wrapFunctional(c.default || c))
export const PaginationPageTwo = () => import('../../components/pagination/page-two.vue' /* webpackChunkName: "components/pagination-page-two" */).then(c => wrapFunctional(c.default || c))
export const PaginationPage = () => import('../../components/pagination/page.vue' /* webpackChunkName: "components/pagination-page" */).then(c => wrapFunctional(c.default || c))
export const PaymentIncludingAddress = () => import('../../components/payment/IncludingAddress.vue' /* webpackChunkName: "components/payment-including-address" */).then(c => wrapFunctional(c.default || c))
export const PaymentStore = () => import('../../components/payment/store.vue' /* webpackChunkName: "components/payment-store" */).then(c => wrapFunctional(c.default || c))
export const ProductAddedToCartModal = () => import('../../components/product/ProductAddedToCartModal.vue' /* webpackChunkName: "components/product-added-to-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCheckbox = () => import('../../components/product/ProductCheckbox.vue' /* webpackChunkName: "components/product-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ProductDeliveryDate = () => import('../../components/product/ProductDeliveryDate.vue' /* webpackChunkName: "components/product-delivery-date" */).then(c => wrapFunctional(c.default || c))
export const ProductNotFound = () => import('../../components/product/ProductNotFound.vue' /* webpackChunkName: "components/product-not-found" */).then(c => wrapFunctional(c.default || c))
export const ProductView = () => import('../../components/product/ProductView.vue' /* webpackChunkName: "components/product-view" */).then(c => wrapFunctional(c.default || c))
export const ProductAdd = () => import('../../components/product/add.vue' /* webpackChunkName: "components/product-add" */).then(c => wrapFunctional(c.default || c))
export const ProductCardThree = () => import('../../components/product/card-three.vue' /* webpackChunkName: "components/product-card-three" */).then(c => wrapFunctional(c.default || c))
export const ProductCardTwo = () => import('../../components/product/card-two.vue' /* webpackChunkName: "components/product-card-two" */).then(c => wrapFunctional(c.default || c))
export const ProductCardView = () => import('../../components/product/card-view.vue' /* webpackChunkName: "components/product-card-view" */).then(c => wrapFunctional(c.default || c))
export const ProductDemo = () => import('../../components/product/demo.vue' /* webpackChunkName: "components/product-demo" */).then(c => wrapFunctional(c.default || c))
export const ProductDesign = () => import('../../components/product/design.vue' /* webpackChunkName: "components/product-design" */).then(c => wrapFunctional(c.default || c))
export const ProductEventHasNoProducts = () => import('../../components/product/eventHasNoProducts.vue' /* webpackChunkName: "components/product-event-has-no-products" */).then(c => wrapFunctional(c.default || c))
export const ProductExample = () => import('../../components/product/example.vue' /* webpackChunkName: "components/product-example" */).then(c => wrapFunctional(c.default || c))
export const ProductPersonalise = () => import('../../components/product/personalise.vue' /* webpackChunkName: "components/product-personalise" */).then(c => wrapFunctional(c.default || c))
export const ProductBrowseCard = () => import('../../components/product/product-browse-card.vue' /* webpackChunkName: "components/product-browse-card" */).then(c => wrapFunctional(c.default || c))
export const ProgressTabs = () => import('../../components/progress/tabs.vue' /* webpackChunkName: "components/progress-tabs" */).then(c => wrapFunctional(c.default || c))
export const RegisterFulfiller = () => import('../../components/register/fulfiller.vue' /* webpackChunkName: "components/register-fulfiller" */).then(c => wrapFunctional(c.default || c))
export const RegisterNow = () => import('../../components/register/now.vue' /* webpackChunkName: "components/register-now" */).then(c => wrapFunctional(c.default || c))
export const RegisterPayment = () => import('../../components/register/payment.vue' /* webpackChunkName: "components/register-payment" */).then(c => wrapFunctional(c.default || c))
export const RegisterTerms = () => import('../../components/register/terms.vue' /* webpackChunkName: "components/register-terms" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsBuyerUs = () => import('../../components/register/termsBuyerUs.vue' /* webpackChunkName: "components/register-terms-buyer-us" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsBuyerUsDe = () => import('../../components/register/termsBuyerUsDe.vue' /* webpackChunkName: "components/register-terms-buyer-us-de" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsBuyerUsFr = () => import('../../components/register/termsBuyerUsFr.vue' /* webpackChunkName: "components/register-terms-buyer-us-fr" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsBuyerUsNl = () => import('../../components/register/termsBuyerUsNl.vue' /* webpackChunkName: "components/register-terms-buyer-us-nl" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsDe = () => import('../../components/register/termsDe.vue' /* webpackChunkName: "components/register-terms-de" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsFr = () => import('../../components/register/termsFr.vue' /* webpackChunkName: "components/register-terms-fr" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsNl = () => import('../../components/register/termsNl.vue' /* webpackChunkName: "components/register-terms-nl" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsShopifyUs = () => import('../../components/register/termsShopifyUs.vue' /* webpackChunkName: "components/register-terms-shopify-us" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsShopifyUsDe = () => import('../../components/register/termsShopifyUsDe.vue' /* webpackChunkName: "components/register-terms-shopify-us-de" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsShopifyUsFr = () => import('../../components/register/termsShopifyUsFr.vue' /* webpackChunkName: "components/register-terms-shopify-us-fr" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsShopifyUsNl = () => import('../../components/register/termsShopifyUsNl.vue' /* webpackChunkName: "components/register-terms-shopify-us-nl" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsUs = () => import('../../components/register/termsUs.vue' /* webpackChunkName: "components/register-terms-us" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsUsDe = () => import('../../components/register/termsUsDe.vue' /* webpackChunkName: "components/register-terms-us-de" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsUsFr = () => import('../../components/register/termsUsFr.vue' /* webpackChunkName: "components/register-terms-us-fr" */).then(c => wrapFunctional(c.default || c))
export const RegisterTermsUsNl = () => import('../../components/register/termsUsNl.vue' /* webpackChunkName: "components/register-terms-us-nl" */).then(c => wrapFunctional(c.default || c))
export const RequiredActions = () => import('../../components/required/actions.vue' /* webpackChunkName: "components/required-actions" */).then(c => wrapFunctional(c.default || c))
export const SelectFilter = () => import('../../components/select/filter.vue' /* webpackChunkName: "components/select-filter" */).then(c => wrapFunctional(c.default || c))
export const SeoEdit = () => import('../../components/seo/edit.vue' /* webpackChunkName: "components/seo-edit" */).then(c => wrapFunctional(c.default || c))
export const SettingsBillingAccount = () => import('../../components/settings/BillingAccount.vue' /* webpackChunkName: "components/settings-billing-account" */).then(c => wrapFunctional(c.default || c))
export const ShopifySignup = () => import('../../components/shopify/signup.vue' /* webpackChunkName: "components/shopify-signup" */).then(c => wrapFunctional(c.default || c))
export const ShoppingBasket = () => import('../../components/shopping/basket.vue' /* webpackChunkName: "components/shopping-basket" */).then(c => wrapFunctional(c.default || c))
export const ShoppingCheckout = () => import('../../components/shopping/checkout.vue' /* webpackChunkName: "components/shopping-checkout" */).then(c => wrapFunctional(c.default || c))
export const SiteAuth = () => import('../../components/site/auth.vue' /* webpackChunkName: "components/site-auth" */).then(c => wrapFunctional(c.default || c))
export const SiteCookies = () => import('../../components/site/cookies.vue' /* webpackChunkName: "components/site-cookies" */).then(c => wrapFunctional(c.default || c))
export const SiteDemo = () => import('../../components/site/demo.vue' /* webpackChunkName: "components/site-demo" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/site/header.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const SiteNav = () => import('../../components/site/nav.vue' /* webpackChunkName: "components/site-nav" */).then(c => wrapFunctional(c.default || c))
export const StatusCard = () => import('../../components/status/card.vue' /* webpackChunkName: "components/status-card" */).then(c => wrapFunctional(c.default || c))
export const StatusCardTwo = () => import('../../components/status/cardTwo.vue' /* webpackChunkName: "components/status-card-two" */).then(c => wrapFunctional(c.default || c))
export const StatusColour = () => import('../../components/status/colour.vue' /* webpackChunkName: "components/status-colour" */).then(c => wrapFunctional(c.default || c))
export const TableColumns = () => import('../../components/table/columns.vue' /* webpackChunkName: "components/table-columns" */).then(c => wrapFunctional(c.default || c))
export const TableView = () => import('../../components/table/view.vue' /* webpackChunkName: "components/table-view" */).then(c => wrapFunctional(c.default || c))
export const TemplateContainer = () => import('../../components/template/TemplateContainer.vue' /* webpackChunkName: "components/template-container" */).then(c => wrapFunctional(c.default || c))
export const TemplatePageWrap = () => import('../../components/template/page-wrap.vue' /* webpackChunkName: "components/template-page-wrap" */).then(c => wrapFunctional(c.default || c))
export const ThankYou = () => import('../../components/thank/you.vue' /* webpackChunkName: "components/thank-you" */).then(c => wrapFunctional(c.default || c))
export const TranslationDashboard = () => import('../../components/translation/dashboard.vue' /* webpackChunkName: "components/translation-dashboard" */).then(c => wrapFunctional(c.default || c))
export const TranslationSite = () => import('../../components/translation/site.vue' /* webpackChunkName: "components/translation-site" */).then(c => wrapFunctional(c.default || c))
export const TribesColorWheel = () => import('../../components/tribes/color-wheel.vue' /* webpackChunkName: "components/tribes-color-wheel" */).then(c => wrapFunctional(c.default || c))
export const TribesContentBlock = () => import('../../components/tribes/content-block.vue' /* webpackChunkName: "components/tribes-content-block" */).then(c => wrapFunctional(c.default || c))
export const TribesContentVideo = () => import('../../components/tribes/content-video.vue' /* webpackChunkName: "components/tribes-content-video" */).then(c => wrapFunctional(c.default || c))
export const TribesCtaStarted = () => import('../../components/tribes/cta-started.vue' /* webpackChunkName: "components/tribes-cta-started" */).then(c => wrapFunctional(c.default || c))
export const TribesEventTypes = () => import('../../components/tribes/event-types.vue' /* webpackChunkName: "components/tribes-event-types" */).then(c => wrapFunctional(c.default || c))
export const TribesFeatureNumbers = () => import('../../components/tribes/feature-numbers.vue' /* webpackChunkName: "components/tribes-feature-numbers" */).then(c => wrapFunctional(c.default || c))
export const TribesGetStarted = () => import('../../components/tribes/get-started.vue' /* webpackChunkName: "components/tribes-get-started" */).then(c => wrapFunctional(c.default || c))
export const TribesHeaderPage = () => import('../../components/tribes/header-page.vue' /* webpackChunkName: "components/tribes-header-page" */).then(c => wrapFunctional(c.default || c))
export const TribesHowItWorks = () => import('../../components/tribes/how-it-works.vue' /* webpackChunkName: "components/tribes-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const TribesPagesNoname = () => import('../../components/tribes/pages-noname.vue' /* webpackChunkName: "components/tribes-pages-noname" */).then(c => wrapFunctional(c.default || c))
export const TribesProductCard = () => import('../../components/tribes/product-card.vue' /* webpackChunkName: "components/tribes-product-card" */).then(c => wrapFunctional(c.default || c))
export const TribesProductFeatures = () => import('../../components/tribes/product-features.vue' /* webpackChunkName: "components/tribes-product-features" */).then(c => wrapFunctional(c.default || c))
export const TribesProductsFull = () => import('../../components/tribes/products-full.vue' /* webpackChunkName: "components/tribes-products-full" */).then(c => wrapFunctional(c.default || c))
export const TribesProductsSnippet = () => import('../../components/tribes/products-snippet.vue' /* webpackChunkName: "components/tribes-products-snippet" */).then(c => wrapFunctional(c.default || c))
export const TribesReviewsNoname = () => import('../../components/tribes/reviews-noname.vue' /* webpackChunkName: "components/tribes-reviews-noname" */).then(c => wrapFunctional(c.default || c))
export const TribesSiteFooter = () => import('../../components/tribes/site-footer.vue' /* webpackChunkName: "components/tribes-site-footer" */).then(c => wrapFunctional(c.default || c))
export const UserAddUserModal = () => import('../../components/user/add-user-modal.vue' /* webpackChunkName: "components/user-add-user-modal" */).then(c => wrapFunctional(c.default || c))
export const UserAddUserTickCircle = () => import('../../components/user/add-user-tick-circle.vue' /* webpackChunkName: "components/user-add-user-tick-circle" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserPermissionCard = () => import('../../components/user/admin-user-permission-card.vue' /* webpackChunkName: "components/user-admin-user-permission-card" */).then(c => wrapFunctional(c.default || c))
export const UserCustomerServiceUserPermissionCard = () => import('../../components/user/customer-service-user-permission-card.vue' /* webpackChunkName: "components/user-customer-service-user-permission-card" */).then(c => wrapFunctional(c.default || c))
export const UserEditUserModal = () => import('../../components/user/edit-user-modal.vue' /* webpackChunkName: "components/user-edit-user-modal" */).then(c => wrapFunctional(c.default || c))
export const UserCard = () => import('../../components/user/user-card.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../components/user/user-icon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const AssetBrandingColorsCard = () => import('../../components/asset/branding/ColorsCard.vue' /* webpackChunkName: "components/asset-branding-colors-card" */).then(c => wrapFunctional(c.default || c))
export const AssetBrandingFontsCard = () => import('../../components/asset/branding/FontsCard.vue' /* webpackChunkName: "components/asset-branding-fonts-card" */).then(c => wrapFunctional(c.default || c))
export const AssetBrandingImagesCard = () => import('../../components/asset/branding/ImagesCard.vue' /* webpackChunkName: "components/asset-branding-images-card" */).then(c => wrapFunctional(c.default || c))
export const EventV2Card = () => import('../../components/event/v2/card.vue' /* webpackChunkName: "components/event-v2-card" */).then(c => wrapFunctional(c.default || c))
export const ProductV2Design = () => import('../../components/product/v2/design.vue' /* webpackChunkName: "components/product-v2-design" */).then(c => wrapFunctional(c.default || c))
export const TableSubBool = () => import('../../components/table/sub/bool.vue' /* webpackChunkName: "components/table-sub-bool" */).then(c => wrapFunctional(c.default || c))
export const TableSubCaps = () => import('../../components/table/sub/caps.vue' /* webpackChunkName: "components/table-sub-caps" */).then(c => wrapFunctional(c.default || c))
export const TableSubClientCrud = () => import('../../components/table/sub/clientCrud.vue' /* webpackChunkName: "components/table-sub-client-crud" */).then(c => wrapFunctional(c.default || c))
export const TableSubCount = () => import('../../components/table/sub/count.vue' /* webpackChunkName: "components/table-sub-count" */).then(c => wrapFunctional(c.default || c))
export const TableSubCrud = () => import('../../components/table/sub/crud.vue' /* webpackChunkName: "components/table-sub-crud" */).then(c => wrapFunctional(c.default || c))
export const TableSubDate = () => import('../../components/table/sub/date.vue' /* webpackChunkName: "components/table-sub-date" */).then(c => wrapFunctional(c.default || c))
export const TableSubEdit = () => import('../../components/table/sub/edit.vue' /* webpackChunkName: "components/table-sub-edit" */).then(c => wrapFunctional(c.default || c))
export const TableSubModal = () => import('../../components/table/sub/modal.vue' /* webpackChunkName: "components/table-sub-modal" */).then(c => wrapFunctional(c.default || c))
export const TableSubObj = () => import('../../components/table/sub/obj.vue' /* webpackChunkName: "components/table-sub-obj" */).then(c => wrapFunctional(c.default || c))
export const TableSubObjstatic = () => import('../../components/table/sub/objstatic.vue' /* webpackChunkName: "components/table-sub-objstatic" */).then(c => wrapFunctional(c.default || c))
export const TableSubPercentage = () => import('../../components/table/sub/percentage.vue' /* webpackChunkName: "components/table-sub-percentage" */).then(c => wrapFunctional(c.default || c))
export const TableSubPrice = () => import('../../components/table/sub/price.vue' /* webpackChunkName: "components/table-sub-price" */).then(c => wrapFunctional(c.default || c))
export const TableSubRevenue = () => import('../../components/table/sub/revenue.vue' /* webpackChunkName: "components/table-sub-revenue" */).then(c => wrapFunctional(c.default || c))
export const TableSubRole = () => import('../../components/table/sub/role.vue' /* webpackChunkName: "components/table-sub-role" */).then(c => wrapFunctional(c.default || c))
export const TableSubSendOrderToFulfiller = () => import('../../components/table/sub/sendOrderToFulfiller.vue' /* webpackChunkName: "components/table-sub-send-order-to-fulfiller" */).then(c => wrapFunctional(c.default || c))
export const TableSubStatic = () => import('../../components/table/sub/static.vue' /* webpackChunkName: "components/table-sub-static" */).then(c => wrapFunctional(c.default || c))
export const TableSubStoreOwnerCustomerRefund = () => import('../../components/table/sub/storeOwnerCustomerRefund.vue' /* webpackChunkName: "components/table-sub-store-owner-customer-refund" */).then(c => wrapFunctional(c.default || c))
export const TableSubSuperadminRefund = () => import('../../components/table/sub/superadminRefund.vue' /* webpackChunkName: "components/table-sub-superadmin-refund" */).then(c => wrapFunctional(c.default || c))
export const TableSubView = () => import('../../components/table/sub/view.vue' /* webpackChunkName: "components/table-sub-view" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
