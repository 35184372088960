import Vue from "vue";

Vue.mixin({
    methods: {
        // Format database strings
        // ========================================================================================
        format_from_db(val) {
            const word = val.replace(/_/g, " ").toLowerCase();

            return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
        },
        format_to_db(val) {
            val = val || ""; // Handle null values

            return val.replace(/ /g, "_").toLowerCase();
        },
        format_word_region(val, region) {
            const words = {
                "colour": "color",
                "Colour": "Color",
                "Colours": "Colors",
                "colours": "colors",
                "COLOUR": "COLOR",
                "Personalise": "Personalize",
                "personalised": "personalized"
            };

            if (region === "US") val = words[val] || val;

            return this.$tc('format_word_region.'+val);
        }
    }
});